<template>
  <section id="tour">
    <h2 id="section-title">TOUR DATES</h2>
    <!-- <img src="@/assets/funsized.png" /> -->
    <div
      id="multidrop"
      data-theme="light"
      data-username="goodkid"
      data-slug="thiscantbetheendtour2023"
      data-color="#65ffe6"
      data-minimal="true"
    ></div>
  </section>
</template>

<style scoped>
#tour {
  max-width: 1200px;
  padding: 0 20px 60px 20px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
#section-title {
  font-family: "blok" !important;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  text-transform: uppercase;
  font-size: 100px;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 10px;
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: "Lato", sans-serif;
  text-transform: none;
  color: #fbc660;
  text-shadow: 1px 2px #964335;
}
</style>
