<template>
  <div id="logo-wrapper">
    <img id="logo" src="@/assets/gk4-logo.png" alt="Good Kid logo" />
  </div>
</template>
<style scoped>
#logo-wrapper {
  position: relative;
  height: 136.96px;
}
#logo {
  position: absolute;
  display: block;
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 200px;
  filter: drop-shadow(0px 0px 4px #888);
  opacity: 0;
  animation: fade-in-and-move-up 1s ease-out 0.5s forwards;
  border: 0;
}
@keyframes fade-in-and-move-up {
  0% {
    opacity: 0;
    top: 50px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
</style>
