<template>
  <div>
    <div class="music-section">
      <img src="@/assets/balloon-sm.png" id="left-floater" class="floater" />
      <div id="video-wrapper">
        <iframe
          width="560"
          height="315"
          id="youtube-embed"
          src="https://www.youtube.com/embed/xtyXoZ8LTHs"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
        ></iframe>
      </div>
    </div>
  </div>
</template>

  <script>
export default {
  name: "MusicSection",
  components: {},
};
</script>
  
  <style scoped>
.music-section {
  padding: 40px;
  filter: drop-shadow(0px -4px 10px grey);
  background: radial-gradient(
    circle,
    rgb(209 199 181) 0%,
    rgb(130 212 215) 100%
  );
  margin: 0 auto;
  text-align: center;
  position: relative;
}
#video-wrapper {
  float: none;
  clear: both;
  width: 100%;
}

#video-wrapper iframe {
  width: 100%;
  max-width: 560px;
}

#left-floater {
  left: 53%;
  transform: translateX(-50%);
}

.floater {
  position: absolute;
  display: inline;
  margin-left: -500px;
  height: 313px;
  animation: mimi-hover 2s ease-in-out 0s infinite;
}

@media screen and (max-width: 500px) {
  #video-wrapper iframe {
    height: 210px;
  }
}

@media screen and (max-width: 1300px) {
  .floater {
    display: none;
  }
}

@keyframes mimi-hover {
  0%,
  100% {
    top: 10px;
  }
  50% {
    opacity: 1;
    top: 30px;
  }
}
</style>
  