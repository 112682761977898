<template>
  <div id="wrapper">
    <img src="@/assets/Jan21-GKxEvvvie.m-004.png" id="photo" />
  </div>
</template>

<style scoped>
#wrapper {
  line-height: 0;
}
#photo {
  width: 100%;
}
</style>
