<template>
  <div id="container">
    <Logo></Logo>
    <NomuCover></NomuCover>
  </div>
</template>
<script>
import Logo from "@/components/GoodkidLogo.vue";
import NomuCover from "@/components/NomuCover.vue";

export default {
  components: {
    Logo,
    NomuCover,
  },
};
</script>
