<template>
  <div id="wrapper">
    <img
      id="nomu-cover"
      src="@/assets/nomumon-small.png"
      alt="The cover art for Good Kid's EP Good Kid 4; it is an illustration of Nomu."
    />
  </div>
</template>

<style scoped>
#nomu-cover {
  max-height: 72vh;
  margin-top: 10px;
  max-width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
#wrapper {
  line-height: 0;
}
</style>
