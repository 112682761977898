<template>
  <div>
    <HeaderSection :relativeLinks="true">
      <IndexBannerVue></IndexBannerVue>
    </HeaderSection>
    <MusicSection id="music"></MusicSection>
    <PhotoSection></PhotoSection>
    <TourSection id="tour"></TourSection>
    <FooterSection :layered="true"></FooterSection>
  </div>
</template>

<script>
import HeaderSection from "@/components/HeaderSection.vue";
import MusicSection from "@/components/MusicSection.vue";
import PhotoSection from "@/components/PhotoSection.vue";
import TourSection from "@/components/TourSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import IndexBannerVue from "./components/IndexBanner.vue";

export default {
  name: "App",
  components: {
    HeaderSection,
    MusicSection,
    PhotoSection,
    TourSection,
    FooterSection,
    IndexBannerVue,
  },
};
</script>

<style>
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f1feff;
}
a {
  color: inherit;
  text-decoration: none;
}
@font-face {
  font-family: "blok";
  src: url("~@/assets/blok.heavy.ttf");
}
</style>
