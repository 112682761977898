import { createApp } from "vue";
import App from "./App.vue";
import { loadScript } from "vue-plugin-load-script";
import VueSmoothScroll from "vue3-smooth-scroll";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
app.use(VueSmoothScroll, { duration: 900 });
app.component("font-awesome-icon", FontAwesomeIcon).mount("#app");

loadScript("https://laylo.com/embeds/multidrop.js");
